/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-04-30 10:59:48
 * @LastEditors: Chen Zhi
 * @LastEditTime: 2021-11-17 16:39:29
 */

export const SECRET_KEY = 'FfZfoOEe1Q3xOefgW74rULxaHnWI6cJv'
export const reportConfig = {
  appKey: '3f1c23c436c0f6de',
  baseURL: process.env.VUE_APP_REPORTBASEURL_API,
  debug: true
}
export const baseURL = process.env.VUE_APP_BASE_API
export const apiMock =
  process.env.NODE_ENV !== 'production' && location.href.includes('mock=true')
export const mockBaseURL = process.env.VUE_APP_MOCK_BASE_API
export const actionName = 'Allo'
export const actType = 'actType'

let isPaypalSandbox = true
if (process.env.NODE_ENV == 'production') {
  isPaypalSandbox = false
}
let paypalInfo = {
  url: 'https://api-m.sandbox.paypal.com',
  clientId:
    'AbT_5209-P20Kp7H-CHhpJUkibfxy6GkOZCyDqXnKnNXn87UHsAnJiTog3ptDfJkjQzHmUXK4e5J3YTM'
}
if (!isPaypalSandbox) {
  paypalInfo = {
    url: 'https://api-m.paypal.com',
    clientId:
      'AXTOJj0f5wg9nnUvI6fu2MeAFsPnMflQ28IJvTPdXY8hKqUDp9HK-CejtmAue9lE5lXDoGKCJmAg38qU'
  }
}

export const paypalUrl = paypalInfo.url
export const clientId = paypalInfo.clientId
// export const codaURL = 'https://sandbox.codapayments.com/airtime/begin'
// export const codaURL = 'https://airtime.codapayments.com/airtime/begin'
export const codaURL = process.env.VUE_APP_CODA_URL
