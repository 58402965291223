/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-04 17:13:23
 * @LastEditors: chenzhi
 * @LastEditTime: 2023-05-26 14:41:41
 */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { apiMock, baseURL, mockBaseURL, SECRET_KEY } from '@/config'
import { Toast } from 'vant'
import md5 from 'js-md5'
import { CommonModule } from '@/store/modules/common'
import { curLanguage } from '.'

const languageObj = {
  ar: 'ar',
  en: 'en',
  turki: 'tr',
  fr: 'fr'
}
export const service = axios.create({
  baseURL,
  timeout: 60 * 1000
})

function _log(response: AxiosResponse<any>) {
  if (process.env.NODE_ENV === 'development') {
    console.log('*==== 请求信息 ====*')
    console.log('地址：', response.config.method, response.config.url)
    const data = response.config.data

    if (data && JSON.stringify(data) != '"{}"') {
      Object.keys(data).length > 0 && console.log('请求参数：', data)
    }
    console.log('响应结果：', response.data)
    console.log('响应配置：', response.config)
    console.log('-------------------------------------------')
  }
}

export function serviceFulfilled(response: AxiosResponse) {
  _log(response)
  // 对响应数据做点什么
  // const data = response.data as IResponse
  if (response.status === 200) {
    // if (data.code == '1001') {
    //   return response.data
    // } else {
    //   Toast(data.message)
    //   return Promise.reject(data)
    // }
    return response.data
  } else {
    Toast(`服务异常【${response.status}】`)
    return Promise.reject(response)
  }
}

export function serviceRejected(error: Error) {
  // 对响应错误做点什么
  console.error(error)
  error.message &&
    Toast(
      error.message == 'Network Error'
        ? 'Ağ isteği başarısız oldu, lütfen tekrar deneyin'
        : error.message
    )
  return Promise.reject(error)
}

export function useMock(config: AxiosRequestConfig) {
  if (apiMock) {
    config.baseURL = mockBaseURL
  }
  return config
}
function addAuthorization(config: AxiosRequestConfig) {
  const Authorization = CommonModule.token || '32c1f9938e909130da52a7a914b8fa28'
  Authorization && (config.headers['Authorization'] = Authorization)
  return config
}

export function addSign(config: AxiosRequestConfig) {
  const timestamp = new Date().getTime()
  const sign = md5(md5(SECRET_KEY) + '&' + timestamp)
  const equipmentKey = CommonModule.deviceId
  const language = CommonModule.language
  config.headers['equipmentKey'] = equipmentKey
  config.headers['timestamp'] = timestamp
  config.headers['sign'] = sign
  config.headers['language'] = language
  config.headers['country'] = languageObj[curLanguage]
  return config
}

service.interceptors.request.use(addAuthorization)
service.interceptors.request.use(useMock)
service.interceptors.request.use(addSign)
service.interceptors.response.use(serviceFulfilled, serviceRejected)
